<template>
  <div class="text-primary text-3xl text-center py-7 border-b border-grey-fp-30">Badges</div>
  <div class="mt-20">
    <p class="text-1xl text-grey-fp-50 mb-10">Classic</p>
    <AppBadge 
      content="Some Content" 
      class="text-lucky-orange cursor-pointer mb-20" 
      custom-classes="inline-flex"
    />

    <p class="text-1xl text-grey-fp-50 mb-10">With Icon</p>
    <AppBadge 
      icon-name="calendar" 
      :icon-size="15" 
      content="Some Content" 
      class="text-lucky-orange cursor-pointer mb-20" 
      custom-classes="inline-flex"
    />

    <p class="text-1xl text-grey-fp-50 mb-10">Using slot</p>
    <AppBadge class="text-lucky-orange cursor-pointer" custom-classes="inline-flex"> 
      <div>
        <p>Some text</p>
        <p>More text</p>
      </div>
    </AppBadge>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs, reactive } from 'vue';

import AppBadge from '@/components/stateless/AppBadge.vue';


export default defineComponent({
  name: 'Tabs',

  components: { AppBadge },

  setup() {
    const state = reactive({ activeTab: {}});
    const { activeTab } = toRefs(state);

    return { activeTab };
  }

});
</script>