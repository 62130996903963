
import { defineComponent } from 'vue';

import AppIcon from '@/components/stateless/AppIcon.vue';

export default defineComponent({
  name: 'AppBadge',

  components: { AppIcon },

  props: {
    iconName: {
      type: String,
      default: ''
    },

    iconSize: {
      type: Number,
      default: 14
    },

    content: {
      type: String,
      default: ''
    },

    customClasses: {
      type: [String, Array, Object],
      default: 'bg-orange-fp-20'
    }
  },

});
