<template>
  <div>
    <div 
      class="rounded-5 items-center px-5" 
      :class="customClasses"
    >
      <AppIcon 
        v-if="iconName" 
        :name="iconName" 
        :size="iconSize" 
        class="mr-5"
      />

      <span 
        v-if="!$slots.default" 
        class="whitespace-nowrap"
      >{{ content }}</span>

      <slot v-else />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import AppIcon from '@/components/stateless/AppIcon.vue';

export default defineComponent({
  name: 'AppBadge',

  components: { AppIcon },

  props: {
    iconName: {
      type: String,
      default: ''
    },

    iconSize: {
      type: Number,
      default: 14
    },

    content: {
      type: String,
      default: ''
    },

    customClasses: {
      type: [String, Array, Object],
      default: 'bg-orange-fp-20'
    }
  },

});
</script>

<style lang="scss" scoped>
</style>
